/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

console.log('Hello World from Webpacker')

require("@rails/ujs").start()
require("trix")
require("@rails/actiontext")

import $ from 'jquery'
import 'select2'
import 'select2/dist/css/select2.css'

function toggleRpps(has_rpps) {
  if (has_rpps) {
    $('#user_rpps_number').prop('required',true)
    $('#no_rpps').prop('required', false);
    $('#rpps').show()
    $('#no_rpps_declaration').hide()
  } else {
    $('#user_rpps_number').prop('required', false)
    $('#no_rpps').prop('required', true);
    $('#rpps').hide()
    $('#no_rpps_declaration').show()
  }
}

function toggleLoginButton() {
  const filled = !!$('#user_email').val() && !!$('#user_password').val();
  const classToAdd = filled ? 'btn-primary' : 'btn-secondary';
  const classToRemove = filled ? 'btn-secondary' : 'btn-primary';
  $('#user-login')[0].classList.remove(classToRemove);
  $('#user-login')[0].classList.add(classToAdd);
  $('#user-login').attr('disabled', !filled);
}

function toggleNurse(isFrench) {
  $('option[value="nurse"]')[0].style.display = isFrench ? 'none' : 'initial';
}



$(document).ready(() => {
  $('.select2').select2({width: '150px'});

  $('.language-select').on('change', function() {
    const newPath = (window.location.pathname.match(/^\/[^\/]*\//)) ?
      window.location.pathname.replace(/^\/[^\/]*\//, '')
      : ''
    const locale = $(this).val()
    const queryString = window.location.search; 
    window.location = window.location.origin + "/" + locale + "/" + newPath + queryString;
  })

  if (localStorage.getItem("country_code_vivacy") === null){
    $('#popup').css('display', 'flex');
  }

  $('.country-select-popup').on('change', function() {
    const country_code = $(this).val().toLowerCase();
    const languageSelect = $('.language-select-popup');
    const lowercaseOptions = languageSelect.find('option').map(function() {
      return $(this).val().toLowerCase();
    }).get();

    const index = lowercaseOptions.indexOf(country_code);
    if (index !== -1) {
      languageSelect.val(country_code);
      languageSelect.trigger('change');
    }
  });


  $('.country-select').on('change', function() {
    const country_code = $(this).val();    
    const newPath = (window.location.pathname.match(/^\/[^\/]*\//)) ?
    window.location.pathname.replace(/^\/[^\/]*\//, '')
    : ''
    localStorage.setItem("country_code_vivacy", country_code);
    const locale = $('.language-select').val()

    const queryString = window.location.search;
    const regex = new RegExp('([?&])country_code=([^&]*)');
    const newQueryString = queryString.replace(regex, function(match, p1, p2) {
        return p1 + 'country_code=' + country_code;
    });
    if (!newQueryString.includes('country_code=')) {
        newQueryString += (newQueryString ? '&' : '?') + 'country_code=' + country_code;
    }
    const newUrl = window.location.origin + '/' + locale + '/' + newPath + newQueryString;
    window.location = newUrl;
  });

    $('nav a').on('click', function(event) {
      event.preventDefault();
      var href = $(this).attr('href');
      var separator = href.indexOf('?') !== -1 ? '&' : '?';
      var newHref = href + separator + 'country_code=' + localStorage.getItem("country_code_vivacy");    
      window.history.replaceState({}, document.title, newHref);
      window.location.href = newHref;
  });

  function addCountryCodeToURL(href) {
    var separator = href.indexOf('?') !== -1 ? '&' : '?';
    return href + separator + 'country_code=' + localStorage.getItem("country_code_vivacy");
  }

  $(document).on('click', 'a', function(event) {
    var href = $(this).attr('href');
    // If it's an internal link (not targeting a different window or a download), modify the URL
    if (href && !href.startsWith('http') && !href.includes('.pdf') && !$(this).attr('target')) {
        event.preventDefault();
        var newHref = addCountryCodeToURL(href);
        // Navigate to the new URL
        window.location.href = newHref;
    }
  });

  $('.close-popup').on('click', function() {
    const newPath = (window.location.pathname.match(/^\/[^\/]*\//)) ?
        window.location.pathname.replace(/^\/[^\/]*\//, '') : '';
    const locale = $('.language-select-popup').val();
    const country_code = $('.country-select-popup').val();
    localStorage.setItem("country_code_vivacy", country_code);
    const queryString = window.location.search;
    let newQueryString = queryString;
    const regex = new RegExp('([?&])country_code=([^&]*)');
    if (queryString.match(regex)) {
        newQueryString = queryString.replace(regex, function(match, p1, p2) {
            return p1 + 'country_code=' + country_code;
        });
    } else {
        newQueryString += (newQueryString ? '&' : '?') + 'country_code=' + country_code;
    }
    const origin = window.location.origin;
    const newUrl = origin + '/' + locale + '/' + newPath + newQueryString;
    window.location.href = newUrl;
    $('.popup').hide();
  });

  $('#scans .report_issue').on('click', function(e) {
    $("#ticket_rfid_number").val($(e.target).attr('rfid_number'));
  });

  const placeholder = $('#ticket_image_label').text();
  $('#ticket_image').on( 'change', function(e) {
    const filename = e.target.files[0] ? e.target.files[0].name : placeholder;
    $('#ticket_image_label').text(filename);
    $('#ticket_image_preview').attr('src', e.target.files[0] ? URL.createObjectURL(e.target.files[0]) : '');
  });

  $('#user_country').on('change', function() {
    const isFrench = window.french_countries.split(',').includes($(this).val());
    toggleRpps(isFrench);
    toggleNurse(isFrench);
  });

  $('.buttons').on('click', function(e) {
    e.preventDefault();
    const country_code = localStorage.getItem("country_code_vivacy");
    if (country_code)
      e.target = e.target + "?country_code=" + country_code;
    window.location= e.target;
    }
  );

  $('#user_country').trigger('change');

  function addCountryCodeToURLAndReload() {
    const country_code = localStorage.getItem("country_code_vivacy");
    if (country_code) {
        const urlParams = new URLSearchParams(window.location.search);
        if (!urlParams.has('country_code')) {
            urlParams.set('country_code', country_code);
            window.location.search = urlParams.toString();
        }
    }
}

  const urlParams = new URLSearchParams(window.location.search);
  if (!urlParams.has('country_code')) {
      addCountryCodeToURLAndReload();
  }

  toggleLoginButton();
  $('#user_email').on('keyup', toggleLoginButton);
  $('#user_password').on('keyup', toggleLoginButton);
})
